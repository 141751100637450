<template>
  <div class="container">
    <div class="d-flex justify-content-between">
      <select
        class="form-control w-50"
        name="month"
        id="month"
        v-model="selectedMonth"
      >
        <option
          v-for="(month, index) in months"
          :key="index"
          :value="month.monthString"
        >
          {{ month.humanReadable }}
        </option>
      </select>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Description</th>
          <th>Booked by</th>
          <th>Contacts</th>
        </tr>
      </thead>

      <template v-if="dates.length > 0">
        <tbody>
          <tr v-for="(date, index) in dates" :key="index">
            <td>{{ date.id }}</td>
            <td>
              {{ date.humanReadable }}
            </td>
            <td>{{ date.event[0].description }}</td>

            <td>{{ date.club[0] ? date.club[0].name : '' }}</td>

            <td>
              <span v-for="(contact, index) in date.contacts" :key="index">
                {{ contact }}<br />
              </span>
            </td>
          </tr>
        </tbody>
      </template>

      <template v-else>
        <div class="d-flex align-items-center justify-content-center">
          <h5>Er zijn nog geen reserveringen in deze maand.</h5>
        </div>
      </template>
    </table>
  </div>
</template>

<script>
import { executeApiCall } from '@/apiClient';
import { format } from 'date-fns';

export default {
  name: 'AdminReservations',

  data() {
    return {
      dates: [],
      months: [],
      optionStartDate: '',
      selectedMonth: null,
    };
  },

  beforeMount() {
    this.getMonths();
    this.getReservations();
  },

  methods: {
    async getMonths() {
      const res = await executeApiCall('post', '/months', { getAll: true });

      if (!res?.ok) {
        alert(res?.data?.message);
        return;
      }

      const dates = res.data?.dates || [];

      this.monthStrings = [];

      this.months = [];

      for (const date of dates) {
        const monthString = format(
          new Date(date.eventDate),
          "yyyy-MM-'01T00:00:00'.SSSxxx"
        );

        if (!this.monthStrings.includes(monthString)) {
          this.monthStrings.push(monthString);

          const humanReadable = format(new Date(date.eventDate), 'MMM yyyy');

          this.months.push({
            monthString,
            humanReadable,
          });
        }
      }

      const todaysMonth = format(new Date(), "yyyy-MM-'01T00:00:00.000'xxx");

      if (!this.monthStrings.includes(todaysMonth)) {
        this.selectedMonth = this.monthStrings[0];
      } else {
        this.selectedMonth = todaysMonth;
      }
    },

    selectMoth(event) {
      this.getReservations(event.target.value);
    },

    async getReservations() {
      this.dates = [];

      const res = await executeApiCall('post', '/reservations', {
        month: this.selectedMonth,
      });

      if (!res?.ok) {
        alert(res?.data?.message);
        return;
      }

      let dates = res.data?.dates || [];

      dates = dates.map((date) => {
        const humanReadable = format(
          new Date(date.event[0].eventDate),
          'eeeeee dd MMMM yyyy'
        );
        return {
          ...date,
          humanReadable,
        };
      });

      this.dates = dates;
    },
  },
  watch: {
    selectedMonth() {
      this.getReservations();
    },
  },
};
</script>
