<template>
  <div class="bottomBar d-flex justify-content-center align-items-center text-center">
    <router-link :to="nextStep" class="d-inline-block" :class="className">
      <div class="btn btn-large w-auto p-0 overflow-hidden" :class="className">
        <div class="btn-overlay" style="padding: 0 120px;">
          Volgende
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['nextStep', 'className'],
};
</script>
