<template>
  <div class="container">
    <div class="d-flex justify-content-end">
      <div class="btn btn-primary btn-openInput" @click="openClubInput($event)">
        {{ btnText }}
      </div>
    </div>

    <br />

    <div ref="clubAdder" class="clubAdder hide">
      <form @submit.prevent="addClub($event)">
        <div class="d-flex flex-column">
          <div class="w-100 mb-4 d-flex">
            <div class="d-flex flex-column ml-2">
              <label for="clubName">clubName</label>
              <input id="clubName" type="text" />
            </div>

            <div class="d-flex flex-column ml-2">
              <label for="chairman">Voorzitter</label>
              <input id="chairman" type="text" />
            </div>

            <div class="d-flex flex-column ml-2">
              <label for="association">Vereniging</label>
              <select name="association" id="association">
                <option value="Voetbal">voetbal</option>
                <option value="hockey">hockey</option>
              </select>
            </div>
          </div>

          <div class="w-100 d-flex">
            <div class="d-flex flex-column ml-2">
              <label for="clubAdres">Adres</label>
              <textarea id="clubAdres"></textarea>
            </div>

            <div class="d-flex flex-column ml-2">
              <label for="clubAdres">Postcode</label>
              <textarea id="clubZipcode"></textarea>
            </div>

            <div class="d-flex flex-column ml-2">
              <label for="clubAdres">Plaats</label>
              <textarea id="clubLocation"></textarea>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary ml-3">add+</button>
      </form>
    </div>

    <label for="searchBar"></label>
    <input id="searchBar" type="text" v-model="search" />

    <table class="table">
      <thead>
        <tr>
          <th class="text-center">#</th>
          <th>naam</th>
          <th>voorzitter</th>
          <th>adres</th>
          <th>slug</th>
          <th>Vereniging</th>
          <th>reservering</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(club, index) in searchClub" :key="`club-${index}`">
          <td>
            {{ club.id }}
          </td>
          <td>
            {{ club.name }}
          </td>
          <td>
            {{ club.chairman }}
          </td>
          <td>
            <span v-for="(adres, index) in club.adres" :key="`adres-${index}`">
              {{ adres }}<br />
            </span>
          </td>
          <td>
            {{ club.slug }}
          </td>
          <td>
            {{ club.association }}
          </td>
          <td v-if="club.event && club.event[0]">
            <span
              v-for="(eventInfo, key, index) in club.event[0]"
              :key="`eventInfo-${index}`"
            >
              <span v-if="key == 'eventDate'">
                eventDate: {{ club.humanReadable }}
                <br />
              </span>

              <span
                v-else-if="
                  key != 'occupied' && key != 'clubId' && key != 'club'
                "
                >{{ key }}: {{ eventInfo }}<br
              /></span>
            </span>
          </td>

          <td v-else></td>
        </tr>
      </tbody>
    </table>

    <div v-if="!dates" class="w-100 text-center">
      <h5>
        Er zijn nog geen events in deze maand.<br />
        Click hier onder om een event toe tevoegen
      </h5>

      <div
        class="btn btn-primary btn-openInput"
        @click="openEventInput($event)"
      >
        open to add
      </div>
    </div>
  </div>
</template>

<script>
import { executeApiCall } from '@/apiClient';
import { format } from 'date-fns';

export default {
  name: 'clubsList',
  data() {
    return {
      msg: 'The commoners',
      clubs: false,
      months: [],
      currEditItemId: false,
      optionStartDate: '',
      btnText: 'open to add club',
      search: '',
    };
  },

  beforeMount() {
    this.getClubs();
  },

  methods: {
    selectMoth(event) {
      this.getDates(event.target.value);
    },

    async getClubs() {
      this.loading = true;
      this.dates = [];

      const res = await executeApiCall('post', '/clubs');

      if (!res?.ok) {
        alert('error getClubs()');
      }

      let clubs = res.data?.clubs.reverse() || [];
      clubs = clubs.map((club) => {
        if (club.event[0]) {
          var humanReadable = format(
            new Date(club.event[0].eventDate),
            'eeeeee dd MMMM yyyy'
          );
        }
        return {
          ...club,
          humanReadable,
        };
      });
      
      this.clubs = clubs;
      this.loading = false;
    },

    openClubInput(event) {
      var el = this.$refs.clubAdder.classList;

      el.toggle('hide');

      if (this.btnText == 'open to add club') {
        this.btnText = 'close';
      } else {
        this.btnText = 'open to add club';
      }
    },

    async addClub(submitEvent) {
      var formElements = submitEvent.target.elements;

      var adresObj = {
        address: formElements.clubAdres.value,
        zipcode: formElements.clubZipcode.value,
        location: formElements.clubLocation.value,
      };

      var clubObj = {
        clubName: formElements.clubName.value,
        chairman: formElements.chairman.value,
        clubAdres: JSON.stringify(adresObj),
        association: formElements.association.value,
      };

      const res = await executeApiCall('post', '/addClub', { clubObj });

      if (!res?.ok) {
        alert('error getClubs()');
      } else {
        res.data.newClub.adres = JSON.parse(res.data?.newClub.adres);

        var currClubs = this.clubs;
        currClubs.unshift(res.data?.newClub);

        this.clubs = currClubs;

        submitEvent.target.reset();
      }
    },
  },

  computed: {
    searchClub() {
      if (this.clubs) {
        return this.clubs.filter(
          (club) =>
            club.name.toLowerCase().indexOf(this.search) != -1 ||
            club.chairman.toLowerCase().indexOf(this.search) != -1 ||
            club.association.toLowerCase().indexOf(this.search) != -1 ||
            club.adres.address.toLowerCase().indexOf(this.search) != -1 ||
            club.adres.location.toLowerCase().indexOf(this.search) != -1 ||
            club.adres.zipcode.toLowerCase().indexOf(this.search) != -1
        );
      }
      return this.clubs;
    },

    todayTime: function () {
      var d = new Date();
      function z(n) {
        return (n < 10 ? '0' : '') + n;
      }
      return (
        d.getFullYear() +
        '-' +
        z(d.getMonth() + 1) +
        '-' +
        z(d.getDate()) +
        'T' +
        z(d.getHours()) +
        ':' +
        z(d.getMinutes()) +
        ':' +
        z(d.getSeconds())
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.clubAdder {
  display: flex;
  max-height: 5000px;
  transition: max-height 1s ease-in-out;
  overflow: hidden;
}

.clubAdder.hide {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
}
</style>
