<template>
  <div class="d-flex flex-column align-items-center">
    <div class="card w-auto p-5">
      <h4>Login</h4>
      <form>
        <label for="email">E-Mail Address</label>
        <div>
          <input id="email" type="email" v-model="email" required autofocus />
        </div>
        <div>
          <label for="password">Password</label>
          <div>
            <input id="password" type="password" v-model="password" required />
          </div>
        </div>
        <div class="pt-3">
          <button type="submit" @click.prevent="handleSubmit">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

import { executeApiCall } from '@/apiClient';

export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    async handleSubmit() {
      if (this.password.length > 0) {
        const result = await executeApiCall('POST', '/login', {
          email: this.email,
          password: this.password,
        });

        if (!result?.ok) {
          alert(result?.data?.message || 'Inloggen niet gelukt');
          return;
        }

        if (result.data?.isAuthenticated) {
          Cookies.set('isAuthorized', true);

          this.$store.dispatch('setUser', result?.data.user);

          if (this.$route.params.nextUrl != null) {
            this.$router.push(this.$route.params.nextUrl);
          } else {
            this.$router.push('events');
          }
        }
      }
    },
  },
};
</script>
