<template>
  <div id="app">
    
    <header>
      <div class="container h-100">
        <div class="d-flex align-items-center justify-content-between h-100">
          <div class="logo pl-0 pr-3 py-3">
            <img src="@/assets/logo.svg">
          </div>

          <div class="d-flex">
            <a href="https://api.whatsapp.com/send?phone=31639620590&text=Hi, ik heb een vraag over de clubfeesten actie met radio 538" target="_new" class="mr-2">
              <div class="btn btn-large w-auto p-0 overflow-hidden">
                <div class="btn-overlay px-4 px-md-3">
                  <i class="fa fa-whatsapp" aria-hidden="true"></i>
                </div>
              </div>
            </a>

            <a href="mailto:info@splitt.nl?subject=Vraagje over de clubfeesten actie met radio 538" target="_new">
              <div class="btn btn-large w-auto p-0 overflow-hidden">
                <div class="btn-overlay px-4 px-md-3">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                </div>
              </div>
            </a>

          </div>
          <!-- <a href="https://www.splitt.nl/contact" target="_new">
            <div class="btn btn-large w-auto p-0 overflow-hidden">
              <div class="btn-overlay px-4 px-md-5">
                contact
              </div>
            </div>
          </a> -->
        </div>

      </div>  
    </header>

    <transition
      :name="transitionName"
      mode="in-out"
      @beforeLeave="beforeLeave"
      style="min-height: 100vh;"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
const DEFAULT_TRANSITION = 'fade';

export default {
  name: 'App',
  data() {
    return {
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION,
      access: false,
    };
  },

  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },

  mounted() {
    setTimeout(function() {
        window.scrollTo(0, 1);
    }, 1000);

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },

  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
  },
};
</script>