<template>
  <ol>
    <li :class="amoundDone >= 1 ? 'disabled' : ''">Kies jullie ideale datum</li>
    <li :class="amoundDone >= 2 ? 'disabled' : ''">
      Vul de contact gegevens in
    </li>
    <li :class="amoundDone >= 3 ? 'disabled' : ''">
      Wij komen langs voor uitleg
    </li>
    <li :class="amoundDone >= 4 ? 'disabled' : ''">
      Splitt wordt geïnstalleerd
    </li>
    <li :class="amoundDone >= 5 ? 'disabled' : ''">
      Het feest wordt opgebouwd
    </li>
    <li :class="amoundDone >= 6 ? 'disabled' : ''">Het clubfeest 🎉</li>
    <li :class="amoundDone >= 7 ? 'disabled' : ''">
      Op naar het volgende clubfeest
    </li>
  </ol>
</template>

<script>
export default {
  props: ['amoundDone'],
};
</script>
