import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    user: null,
    club: false,
    currentReservation: false,
  },
  mutations: {
    setUser(state, userData) {
      state.user = userData;
    },

    setClubDetails(state, clubData) {
      state.club = clubData;
    },

    setCurrentReservation(state, ReservationObj) {
      state.currentReservation = ReservationObj;
    },
  },
  actions: {
    setUser(store, data) {
      return new Promise((resolve) => {
        store.commit('setUser', data);

        resolve();
      });
    },

    setClubDetails(store, data) {
      return new Promise((resolve, reject) => {
        var clubData = {
          id: data.clubId,
          name: data.clubName,
          code: data.clubCode,
        };

        store.commit('setClubDetails', clubData);

        resolve();
      });
    },

    setCurrentReservation(store, ReservationObj) {
      return new Promise((resolve, reject) => {
        store.commit('setCurrentReservation', ReservationObj);
        resolve();
      });
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },

    getClubData(state) {
      return state.club;
    },

    getCurrentReservation(state) {
      return state.currentReservation;
    },
  },
});
