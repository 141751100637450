<template>
  <div>
    <header class="mb-5">
      <nav class="navbar navbar-expand-lg bg-primary">
        <div class="container">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <!-- class="nav-link" -->
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link class="nav-link" to="/admin/events">
                  Events
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/admin/reservations">
                  reservations
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/admin/clubs">
                  clubs
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
};
</script>
<style scoped lang="css">
.router-link-active {
  background-color: hsla(0, 0%, 100%, 0.1);
}
</style>
