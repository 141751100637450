import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import AdminApp from '@/views/admin/App';
import AdminClubs from '@/views/admin/Clubs';
import AdminEvents from '@/views/admin/Events';
import AdminLogin from '@/views/admin/Login';
import AdminReservations from '@/views/admin/Reservations';

import MiddlewareStylesheet from './middleware/stylesheet';

import Cookies from 'js-cookie';

Vue.use(VueRouter);

const routes = [
  {
    path: '/admin',
    name: 'AdminApp',
    component: AdminApp,
    children: [
      {
        path: '/admin',
        redirect: '/admin/login',
      },
      {
        path: '/admin/login',
        name: 'AdminLogin',
        component: AdminLogin,
        meta: {
          stylesheet: 'admin',
        },
      },
      {
        path: '/admin/events',
        name: 'AdminEvents',
        component: AdminEvents,
        meta: {
          requiresAuth: true,
          stylesheet: 'admin',
        },
      },
      {
        path: '/admin/reservations',
        name: 'AdminReservations',
        component: AdminReservations,
        meta: {
          requiresAuth: true,
          stylesheet: 'admin',
        },
      },
      {
        path: '/admin/clubs',
        name: 'AdminClubs',
        component: AdminClubs,
        meta: {
          requiresAuth: true,
          stylesheet: 'admin',
        },
      },
    ],
  },
  {
    path: '/:club/:code',
    name: 'Home',
    component: Home,
    meta: {
      transitionName: 'fade',
      stylesheet: 'public',
    },
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('../views/Calendar.vue'),
    meta: {
      transitionName: 'fade',
      stylesheet: 'public',
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts.vue'),
    meta: {
      transitionName: 'fade',
      stylesheet: 'public',
    },
  },
  {
    path: '/thnx',
    name: 'Thanks',
    component: () => import('../views/Thanks.vue'),
    meta: {
      transitionName: 'fade',
      stylesheet: 'public',
    },
  },
  {
    name: 'NotFound',
    path: '*',
    component: Home,
    meta: {
      transitionName: 'fade',
      stylesheet: 'public',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(MiddlewareStylesheet);

router.beforeEach((to, from, next) => {
  const isAuthorized = !!Cookies.get('isAuthorized');

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthorized) {
      next({
        path: '/admin/login',
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
