<template>
  <div class="container">
    <div class="d-flex justify-content-between">
      <select
        class="form-control w-50"
        ref="month"
        name="month"
        v-model="selectedMonth"
      >
        <option
          v-for="(month, index) in months"
          :key="`month-${index}`"
          :value="month.monthString"
        >
          {{ month.humanReadable }}
        </option>
      </select>

      <div
        class="btn btn-primary btn-openInput"
        @click="toggleEventInput($event)"
      >
        {{ openInputBtnText }}
      </div>
    </div>

    <br />

    <div class="eventAdder" :class="eventAdderOpen ? '' : 'hide'">
      <input
        type="datetime-local"
        id="meeting-time"
        name="meeting-time"
        ref="meetingTime"
        :value="todayTime"
        :min="todayTime"
        max="2025-06-14T00:00"
      />

      <input
        class="w-50 ml-3"
        type="text"
        id="eventDescription"
        ref="eventDescription"
        :placeholder="'Leeg: Met o.a ' + getUser.name"
      />

      <div class="btn btn-primary ml-3" @click="addEvent()">add+</div>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Description</th>
          <th>Booked by</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(date, index) in dates" :key="`data-${index}`">
          <td>{{ date.id }}</td>
          <td>
            <span v-if="date.humanReadable"> {{ date.humanReadable }} </span>
          </td>
          <td v-html="date.description"></td>
          <td>{{ date.club ? date.club.name : '' }}</td>
          <td class="td-actions text-right">
            <button
              type="button"
              rel="tooltip"
              class="btn btn-success"
              @click="editModal(date.id)"
            >
              <i class="material-icons">edit</i>
            </button>
            <button
              type="button"
              rel="tooltip"
              class="btn btn-danger"
              @click="removeEvent(date.id)"
            >
              <i class="material-icons">delete</i>
            </button>
          </td>
          <br />
        </tr>
      </tbody>
    </table>

    <div v-if="!dates" class="w-100 text-center">
      <h5>
        Er zijn nog geen events in deze maand.<br />
        Click hier onder om een event toe tevoegen
      </h5>

      <div
        class="btn btn-primary btn-openInput"
        @click="toggleEventInput($event)"
      >
        open to add
      </div>
    </div>

    <div
      id="eventEditModal"
      class="modal"
      data-eventId=""
      :class="eventEditModalOpen ? 'd-block' : ''"
    >
      <!-- Modal content -->
      <div class="modal-content">
        <span @click="closeEditModal" class="close">&times;</span>
        <div class="w-75 ml-auto mr-auto d-flex mb-3">
          <input
            class="w-50 mr-2"
            type="datetime-local"
            id="meeting-time-Edit"
            ref="meetingTimeEdit"
            name="meeting-time"
            :min="todayTime"
            max="2025-06-14T00:00"
          />

          <input
            class="w-50 ml-2"
            type="text"
            id="eventDescription-Edit"
            ref="eventDescription_Edit"
          />

          <br />
        </div>
        <div class="w-75 m-auto">
          <button
            id="editEvent"
            type="button"
            rel="tooltip"
            class="btn btn-success w-25"
            @click="editEvent($event)"
          >
            <i class="material-icons">edit</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { format } from 'date-fns';

import { executeApiCall } from '@/apiClient';

import { mapGetters } from 'vuex';

export default {
  name: 'Events',
  data() {
    return {
      selectedMonth: null,
      dates: [],
      months: [],
      monthStrings: [],
      currEditItemId: false,
      optionStartDate: '',
      eventAdderOpen: false,
      openInputBtnText: 'open to add',
      eventEditModalOpen: false,
    };
  },

  beforeMount() {
    this.getMonths();
    this.getDates();
  },

  mounted() {

  },

  methods: {
    async getMonths() {
      const res = await executeApiCall('post', '/months', { getAll: true });

      if (!res?.ok) {
        alert(res?.data?.message);
        return;
      }

      const dates = res.data?.dates || [];

      this.monthStrings = [];

      this.months = [];

      for (const date of dates) {
        const monthString = format(
          new Date(date.eventDate),
          "yyyy-MM-'01T00:00:00'.SSSxxx"
        );

        if (!this.monthStrings.includes(monthString)) {
          this.monthStrings.push(monthString);

          const humanReadable = format(new Date(date.eventDate), 'MMM yyyy');

          this.months.push({
            monthString,
            humanReadable,
          });
        }
      }

      const todaysMonth = format(new Date(), "yyyy-MM-'01T00:00:00.000'xxx");

      if (!this.monthStrings.includes(todaysMonth)) {
        this.selectedMonth = this.monthStrings[0];
      } else {
        this.selectedMonth = todaysMonth;
      }
    },

    async getDates() {
      this.dates = [];

      const res = await executeApiCall('post', '/dates', {
        month: this.selectedMonth,
      });

      if (!res?.ok) {
        alert(res?.data?.message);
        return;
      }

      let dates = res.data?.dates || [];

      dates = dates.map((date) => {
        const humanReadable = format(
          new Date(date.eventDate),
          'eeeeee dd MMMM yyyy'
        );
        return {
          ...date,
          humanReadable,
        };
      });

      this.dates = dates;
    },

    toggleEventInput(event) {
      if (this.eventAdderOpen) {
        this.eventAdderOpen = false;
        this.openInputBtnText = 'open to add';
      } else {
        this.eventAdderOpen = true;
        this.openInputBtnText = 'close';
      }
    },

    async addEvent() {
      var vm = this;
      var description;

      if (this.$refs.eventDescription.value == '') {
        description = 'Met o.a. ' + this.getUser.name;
      } else {
        description = this.$refs.eventDescription.value;
      }

      var eventObj = {
        user: this.getUser.name,
        eventDate: this.$refs.meetingTime.value,
        description: description,
      };

      const res = await executeApiCall('post', '/addEvent', { eventObj });

      if (!res?.ok) {
        alert(res?.data?.message);
      } else {
        var eventDate = res.data?.newEvent.eventDate;
        var newDate = format(
          new Date(eventDate),
          "yyyy-MM-'01T00:00:00'.SSSxxx"
        );
        
        vm.selectedMonth = newDate;
        vm.getMonths();
        vm.getDates();

        this.$refs.eventDescription.value = '';
      }
    },

    async removeEvent(eventId) {
      var vm = this;
      var eventObj = {
        eventId: eventId,
      };

      const res = await executeApiCall('post', '/removeEvent', { eventObj });

      if (!res?.ok) {
        alert(res?.data?.message);
      } else {
        var newDate = format(
          new Date(res.data?.oldEvent.eventDate),
          "yyyy-MM-'01T00:00:00'.SSSxxx"
        );
        this.$refs.month.val = newDate;
        vm.selectedMonth = newDate;
        vm.getDates(newDate);
      }
    },

    editModal(eventId) {
      var event;

      this.currEditItemId = eventId;

      for (var i = this.dates.length - 1; i >= 0; i--) {
        if (this.dates[i].id == this.currEditItemId) {
          event = this.dates[i];
          continue;
        }
      }
      var currDateTime = event.eventDate.substring(
        0,
        event.eventDate.length - 1
      );
      this.$refs.meetingTimeEdit.value = currDateTime;
      this.$refs.eventDescription_Edit.value = event.description;
      if (this.eventEditModalOpen) {
        this.eventEditModalOpen = false;
      } else {
        this.eventEditModalOpen = true;
      }
    },

    closeEditModal() {
      this.eventEditModalOpen = false;
    },

    async editEvent(event) {
      var vm = this;
      var eventObj = {
        eventId: this.currEditItemId,
        user: this.getUser.name,
        eventDate: this.$refs.meetingTimeEdit.value,
        description: this.$refs.eventDescription_Edit.value,
      };

      const res = await executeApiCall('post', '/editEvent', { eventObj });

      if (!res?.ok) {
        alert(res?.data?.message);
      } else {
        var newDate = format(new Date(res.data?.oldEvent.eventDate), "yyyy-MM-'01T00:00:00'.SSSxxx");
        this.$refs.month.val = newDate;
        vm.selectedMonth = newDate;
        vm.getDates(newDate);
        this.eventEditModalOpen = false;
      }
    },
  },

  computed: {
    ...mapGetters(['getUser']),

    todayTime: function () {
      var d = new Date();
      function z(n) {
        return (n < 10 ? '0' : '') + n;
      }
      return (
        d.getFullYear() +
        '-' +
        z(d.getMonth() + 1) +
        '-' +
        z(d.getDate()) +
        'T' +
        z(d.getHours()) +
        ':' +
        z(d.getMinutes()) +
        ':' +
        z(d.getSeconds())
      );
    },
  },

  watch: {
    selectedMonth() {
      this.getDates();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.eventAdder {
  display: flex;
  max-height: 5000px;
  transition: max-height 1s ease-in-out;
}

.eventAdder.hide {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
