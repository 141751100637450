<template>
  <div class="home">
    
    <loader v-if="loading"></loader>
    
    <div class="container h-100 d-flex align-items-center">
      <div class="row">
        <div class="col-12 col-md-6 d-flex align-items-center">
          <div class="content w-100">
            <div class="title pb-sm-4">
              <h4>Welkom {{ getClubData.name }}</h4>
            </div>

            <p class="text pb-sm-4">
              Gefeliciteerd! {{ getClubData.name }} krijgt een clubfeest met een
              Radio 538-DJ cadeau van Splitt. Het doel van dit feest is: het
              clubgevoel versterken én zoveel mogelijk omzet realiseren voor de
              clubkas van {{ getClubData.name }}. De stappen:
            </p>
          </div>
        </div>

        <div class="col-12 col-md-6 d-flex align-items-center">
          <div class="card card__steplist w-100 py-4 px-5">
            <stepList :amoundDone="0"></stepList>
          </div>
        </div>
      </div>
    </div>

    
    <nextButton
      :nextStep="nextStep"
      :className="getClubData ? '' : 'disabled'"
      class="mt-auto w-100"
    ></nextButton>

    <errorModal
      v-if="error"
      :whatsappMSG="whatsappMSG"
      :errorTitle="errorTitle"
      :errorMsg="errorMsg"
    ></errorModal>

  </div>
</template>

<script>
import nextButton from '@/components/next_button.vue';
import stepList from '@/components/step_list.vue';
import loader from '@/components/loader.vue';
import errorModal from '@/components/errorModal.vue';

import { mapGetters, mapActions } from 'vuex';

import { executeApiCall } from '@/apiClient';

export default {
  name: 'Home',
  components: {
    nextButton,
    stepList,
    loader,
    errorModal,
  },
  data() {
    return {
      nextStep: '/calendar',
      alreadyDone: false,
      loading: true,
      whatsappMSG:
        'Hoi! Ik kan de clubactie niet laden. Kunnen jullie me hierbij helpen?',
      errorTitle: 'Geen gegevens gevonden',
      errorMsg:
        'Heeft u de url juist ingevuld? Probeer het nog een keer of stuur een bericht naar onze klanten service.',
      error: false,
    };
  },

  beforeMount() {
    this.getClubDetails();
  },

  methods: {
    ...mapActions(['setClubDetails']),

    async getClubDetails() {
      const path = this.$route.params.club + '/' + this.$route.params.code;

      const res = await executeApiCall('get', `/club/${path}`);

      if (!res?.ok) {
        this.loading = false;
        this.error = true;
      } else {
        this.alreadyDone = res.data?.alreadyDone.length;
        this.setAndCheckClubDetails(res.data);
      }

      if (this.alreadyDone) {
        this.errorTitle = 'U heeft al gereserveerd';
        this.errorMsg =
          'Heeft u de url juist ingevuld? Probeer het nog een keer of stuur een bericht naar onze klanten service';
        this.whatsappMSG =
          'Hoi! Er staat dat ik al heb gereserveerd. Kunnen jullie me hierbij helpen?';
        this.error = true;
      }
    },

    setAndCheckClubDetails(data) {
      this.setClubDetails(data).then(() => {
        this.loading = false;
      });
    },
  },

  computed: {
    ...mapGetters(['getClubData']),
  },
};
</script>
