<template>
  <div>
    <div
      class="modal fade show"
      id="myModal"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
          </div>
          <div class="modal-body">
            <p style="color: #000">
              Heeft u de url juist ingevuld? Probeer het nog een keer of stuur
              een bericht naar onze klanten service
            </p>
          </div>
          <div class="modal-footer">
            <button @click="sendMSG" type="button" class="btn btn-default">
              Stuur een bericht
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" id="backdrop"></div>
  </div>
</template>

<script>
export default {
  props: ['whatsappMSG', 'errorTitle', 'errorMsg'],
  data() {
    return {
      title: this.$props.errorTitle,
    };
  },

  methods: {
    sendMSG() {
      window.location.href = `https://api.whatsapp.com/send?phone=31639620590&text=${encodeURIComponent(
        this.$props.whatsappMSG
      )}`;
    },
  },
};
</script>
