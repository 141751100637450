export const executeApiCall = async (method, path, data) => {
  const baseUrl = process.env.VUE_APP_API_HOST;

  // !!!!!!!!!!!!!!!!!LOCAL!!!!!!!!!!!!!!!!!//
  // const baseUrl = 'http://10.0.0.63:3000';

  const headers = {
    'Content-Type': 'application/json',
  };

  const options = {
    method,
    headers,
    credentials: 'include',
  };

  if (data) {
    if (method.toLowerCase() === 'get') {
      const urlParams = new URLSearchParams(Object.entries(data));
      path = `${path}?${urlParams.toString()}`;
    } else {
      options.body = JSON.stringify(data);
    }
  }

  let response = null;

  try {
    response = await fetch(`${baseUrl}${path}`, options);
  } catch (e) {
    console.error(`Could not connect to API:`, e);
    throw new Error('Could not connect to API');
  }

  let parsedResponse = null;

  try {
    parsedResponse = await response.json();
  } catch (e) {
    console.error(`Could not parse response as json:`, e);
    throw new Error('Could not parse response as json');
  }

  return {
    ok: response?.ok,
    data: parsedResponse,
  };
};
